.header .navbar-brand {
    height: 60px !important;
}

.is-invalid {
    font-size: 11px !important;
    color: red !important;
}

.cDmETx {
    min-height: 0px !important;
}

.widget {
    background-color: #5198c7 !important;
    margin: '0px 15px 20px 0px';
}

.checkbox {
    font-size: 4px !important;
    /* margin-top: 5px; */
    padding: 10px;
}

.labelcheckbox {
    font-size: 13px !important;
    /* margin-top: 5px; */
    padding-left: 9px;
    padding-right: 46px;
}

.paneluserp {
    height: 300px !important;
}

.logotext {
    /* height: 128px !important; */
    height: 15px !important;
    width: 70px !important;
    margin-top: 0.3em !important;
}

.logoimg {
    margin-left: 50px !important;
}

.push-left {
    /* left:57px !important; */
}

.titlelogin {
    color: #22222496;
    font-size: 9pt;
    font-weight: bold;
    margin-top: -20px;
    margin-bottom: 40px;
}

.badge {
    padding: 5px;
    margin-left: 5px;
}

.link {
    cursor:pointer !important;
}

.error {
    margin-top: 15px;
    height: 0px !important;
    color: #f77373;
    font-size: 10px;
    text-align: left;
}

.square_destk {
    border: 1px solid #d5dbe0;
    border-radius: 4px;
    padding: 10px;
    margin-top: 20px;
    
}